<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout mb-5">
          <div class="md-layout-item md-size-33">
            <StudentsAutocomplete
              ref="studentsAutocomplete"
              @change="onSelectedStudent"
            />
          </div>
          <div class="md-layout-item md-size-33">
            <form-date
              v-model="filters.from"
              label="Record Date From"
            />
          </div>
          <div class="md-layout-item md-size-33">
            <form-date
              v-model="filters.to"
              label="Record Date To"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-primary md-sm w-100"
              @click="onDownload"
            >
              Download XLS
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              :search="false"
              @changed="getData"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { FormDate } from '@/components/Inputs';
import { StudentsAutocomplete } from '@/components/Inputs/selects/index';
import downloadFile from '@/utils/downloadFile';
import moment from 'moment';

export default {
  components: {
    VueTable,
    FormDate,
    StudentsAutocomplete,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'student_number',
            mask: 'Student No',
            no_format: true,
            sortable: true,
          },
          {
            title: 'name',
          },
          {
            title: 'email',
          },
          {
            title: 'record_time',
            mask: 'record time',
            sortable: true,
            dateTimeFormat: true,
          },
          {
            title: 'verify_type',
            mask: 'verify type',
          },
          {
            title: 'classroom_ip',
            mask: 'classroom ip',
          },
        ],
        actions: [],
        values: {},
      },
      filters: {
        from: null,
        to: null,
        student_number: null,
      },
      loading: false,
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    onFilter() {
      this.$refs.vtable.init();
    },
    onSelectedStudent(val) {
      if (!val) return;

      this.filters.student_number = val.student_number;
    },
    onDownload() {
      this.loading = true;
      this.request('biometric/students_records', 'download', { ...this.filters, download: true }, ({ data }) => {
        const filename = `students_records_${moment().format('YYYY_MM_DD')}.xlsx`;
        downloadFile(data, filename);
      }, () => {
        this.loading = false;
      });
    },
    getData(params) {
      this.request('biometric/students_records', 'get', { ...params, ...this.filters }, ({ data }) => {
        this.vTable.values = data;
        this.vTable.values.data = this.vTable.values.data.map((x) => {
          x.onResendActivation = !x.is_active;
          x.onDelete = x.is_active;
          return x;
        });
      });
    },
  },
};
</script>

<style scoped>
</style>
